import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { languages } from '@shared/helpers/languages.helper';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { ICountry } from '@shared/interfaces/country.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { IQuestionSet } from '@shared/interfaces/question-set.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { IReferenceContact } from '@shared/interfaces/reference-contact.interface';
import { IReferenceRelationship } from '@shared/interfaces/reference-relationship.interface';
import { IReference } from '@shared/interfaces/reference.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { ReferenceContact } from '@shared/models/reference-contact.model';
import { Reference } from '@shared/models/reference.model';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { HiringFirmsService } from '@shared/services/hiring-firms.service';
import { LoggerService } from '@shared/services/logger.service';
import { QuestionSetsService } from '@shared/services/question-sets.service';
import { RecruitersService } from '@shared/services/recruiters.service';
import { ReferenceContactsService } from '@shared/services/reference-contacts.service';
import { ReferencesService } from '@shared/services/references.service';
import { ToastService } from '@shared/services/toast.service';
import { EmailValidityValidator } from '@shared/validators/emailValidity.validators';
import { NumbersOnlyValidator } from '@shared/validators/numbersOnly.validators';
import { PhoneEmailRequiredValidator } from '@shared/validators/phoneEmailRequired.validators';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

import { LocalStorage, SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-modals-references',
  templateUrl: './modals.references.component.html'
})
export class ModalsReferencesComponent
  extends ModalDirective<IReference>
  implements OnInit
{
  @Input() public isQSEditable = Boolean(true);

  @LocalStorage() public isSubAccount: boolean;

  @SessionStorage() public settings: ISetting;

  @SessionStorage() private countries: ICountry[];
  @SessionStorage() private hiringFirm: IHiringFirm;

  public candidate: Partial<ICandidate>;
  public reference: IReference;

  public title = String('MODALS.REFERENCES.ADD.TITLE');
  public description = String('MODALS.REFERENCES.DESCRIPTION');

  public submitKey = String('BUTTONS.SUBMIT');
  public entry: FormGroup;
  public questionSets: IQuestionSet[] = [];
  public isButtonsDisabled = Boolean(false);
  public isReferenceRequestSent = Boolean(false);
  public isReferenceContact = Boolean(false);
  public isReferenceEmailRequired = Boolean(true);
  public params: any = {
    type: 'reference'
  };

  public relationships: IReferenceRelationship[] = [];
  public languages: any[] = [];
  public recruiters: IRecruiter[] = [];
  public language = String('en');

  public bsConfig: any = {
    containerClass: 'v-datepicker v-datepicker-range',
    dateInputFormat: 'll',
    rangeInputFormat: 'll',
    maxDate: new Date(),
    showWeekNumbers: false,
    adaptivePosition: true
  };

  public isMultiLanguagesEnable = Boolean(false);

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _fb: FormBuilder,
    private readonly _hiringFirms: HiringFirmsService,
    private readonly _recruiters: RecruitersService,
    private readonly _references: ReferencesService,
    private readonly _referenceContacts: ReferenceContactsService,
    private readonly _questionSets: QuestionSetsService,
    private readonly _toast: ToastService,
    private readonly _logger: LoggerService,

    private readonly _emailValidityValidator: EmailValidityValidator
  ) {
    super();
  }

  public get isEmailRequired(): boolean {
    if (!this.isReferenceEmailRequired) {
      return false;
    }

    const control = this.entry.get('email') as FormGroup;
    const validator =
      control?.validator && control?.validator({} as AbstractControl);
    return validator?.required;
  }

  public get isPhoneRequired(): boolean {
    const control = this.entry.get('phone') as FormGroup;
    const validator =
      control?.validator && control?.validator({} as AbstractControl);
    return validator?.required;
  }

  ngOnInit(): void {
    this.openModal.subscribe(
      (res: { candidate: ICandidate; reference: IReference }) => {
        this.candidate = res.candidate;
        this.reference = res.reference;

        this.isReferenceContact = this.reference instanceof ReferenceContact;
        this.isMultiLanguagesEnable =
          this.settings?.is_plugins_multi_languages_enabled;
        this.isReferenceEmailRequired =
          this.settings?.is_reference_email_required;

        this.languages = languages.filter(
          (l: any) => this.settings.languages.indexOf(l.id) > -1
        );

        this.getQuestionSets();

        const country = this.countries.find((c: ICountry) => {
          if (!!this.reference?.phone) {
            // Check if the phone code matches and is not '1' (likely excluding default/US country code)
            if (c.phoneCode === this.reference?.country_code && this.reference?.country_code !== '1') {
              return true;
            // Check if the phone code matches and the initial matches the hiring firm's country
            } else if (c.phoneCode === this.reference?.country_code && c.initial === this.reference.hiring_firm?.country) {
              return true;
            }
          } else {
            // When no reference phone exists, check if the initial matches the hiring firm's country
            if (c.initial === this.hiringFirm?.country) {
              return true;
            }
          }
          return false;
        });

        if (!!country) {
          this.entry.patchValue({
            country_code: country?.phoneCode,
            countryInitial: country?.initial
          });
        }

        if (!!this.reference) {
          if (this.isReferenceContact) {
            const token = this._commonEnvironments.getToken();
            const payload: IPayload = jwtDecode(token);

            this.entry.patchValue({
              ...this.reference,
              request_by: payload.recruiter_id
            });
          } else {
            this.title = 'MODALS.REFERENCES.EDIT.TITLE';
            this.submitKey = 'BUTTONS.SAVE';

            const endDateControl = this.entry.get('end_date') as FormGroup;
            if (this.settings.is_reference_dates_enabled) {
              if (!!!this.reference.end_date) {
                endDateControl.disable();
                this.entry.patchValue({ isCurrent: true });
              } else {
                endDateControl.enable();
                this.entry.patchValue({ isCurrent: false });
              }

              endDateControl.updateValueAndValidity();
            }

            this.entry.patchValue({
              ...this.reference,
              request_by: this.reference.requested_by_id,
              question_set: this.reference.question_set?.id,
              start_date: this.reference.start_date
                ? moment(this.reference?.start_date).toDate()
                : null,
              end_date: this.reference?.end_date
                ? moment(this.reference?.end_date).toDate()
                : null
            });
          }
        } else {
          this.isReferenceRequestSent =
            this.candidate.reference_requests_status.not_completed > 0;

          const token = this._commonEnvironments.getToken();
          const payload: IPayload = jwtDecode(token);

          this.entry.patchValue({
            request_by: payload.recruiter_id
          });
        }

        this.getReferenceRelationships();
        this.getRecruiters();

        const emailControl = this.entry.controls['email'];

        if (this.isReferenceEmailRequired) {
          emailControl.setValidators([Validators.email, Validators.required]);
        } else {
          emailControl.setValidators([Validators.email]);
        }

        // emailControl.setAsyncValidators([
        //   this._emailValidityValidator.validates
        // ]);

        emailControl.updateValueAndValidity({
          onlySelf: true,
          emitEvent: false
        });
      }
    );

    this.createForm();
  }

  public getQuestionSets() {
    const url = 'GET /question_sets';
    const filters = {
      page_size: 50,
      filter: {
        key: 'qs',
        value: 'scope:reference'
      }
    };

    this._questionSets.get(filters).subscribe(
      (res: IQuestionSet[]) => {
        this._logger.info(this.constructorName, url, res);

        this.questionSets = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
      }
    );
  }

  public onDismiss() {
    this.isButtonsDisabled = false;
    this.entry.enable();
    this.errors = [];

    this.resetForm();
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.isButtonsDisabled = true;
      this.entry.disable();
      this.errors = [];

      value = new Reference({
        ...value,
        request_by: this.recruiters.find(
          (r: IRecruiter) => r.id === value.request_by
        ),
        question_set: this.questionSets.find(
          (qs: IQuestionSet) => qs.id === value.question_set
        ),
        end_date: value.end_date || null
      });

      if (this.isReferenceContact) {
        value.candidate = this.candidate;

        this.post(value);
      } else {
        if (this.candidate) {
          value.candidate = this.candidate;
        } else if (this.reference && this.reference.candidate) {
          value.candidate = this.reference.candidate;
        }

        if (this.reference && this.reference.profile) {
          value.profile = this.reference.profile;
        }

        if (value.id) {
          this.patch(value);
        } else {
          this.post(value);
        }
      }
    }
  }

  public resetForm() {
    this.isButtonsDisabled = false;
    this.entry.enable();
    super.resetForm();
  }

  public setDefaultQuestionSet() {
    if (this.questionSets?.length > 0) {
      const defaultQs = this.questionSets.find(
        (qs: IQuestionSet) => qs.is_default
      );

      let qsId = this.questionSets[0].id;

      if (!!defaultQs) {
        qsId = defaultQs.id;
      }

      this.entry.patchValue({
        question_set: qsId
      });
    }
  }

  public updateCurrentDate() {
    const control = this.entry.controls['end_date'];
    const isCurrent = this.entry.get('isCurrent').value;

    if (!isCurrent) {
      control.setValidators([Validators.required]);
      control.enable();
    } else {
      this.entry.patchValue({
        end_date: null
      });

      control.setValidators([]);
      control.disable();
    }

    control.updateValueAndValidity();
  }

  protected createForm() {
    this.entry = this._fb.group(
      {
        id: [''],
        language: ['en', [Validators.required]],
        question_set: ['', [Validators.required]],
        email: [
          '',
          [Validators.email]
          // [this._emailValidityValidator.validates]
        ],
        first_name: ['', [Validators.required]],
        last_name: ['', [Validators.required]],
        title: ['', [Validators.maxLength(63)]],
        company_name: [''],
        relationship: ['U'],
        phone: ['', [Validators.required, NumbersOnlyValidator.NumbersOnly]],
        countryInitial: [''],
        country_code: [''],
        notes: [''],
        specialty: [''],
        start_date: [''],
        end_date: [''],
        candidate_position: [''],
        isCurrent: [false, [Validators.required]],
        request_by: ['', [Validators.required]]
      },
      {
        validator: PhoneEmailRequiredValidator.DynamicRequired(
          'phone',
          'email',
          false
        )
      }
    );
  }

  private patch(value: IReference) {
    const url = `PATCH /references/${value.id}`;
    this._references.patch(value).subscribe(
      (res: IReference) => {
        this._logger.info(this.constructorName, url, res);

        this._toast.success('Reference updated');
        this.resetModal();
        this.resetAll();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
        this.resetAll();
      }
    );
  }

  private post(value: any) {
    const url = 'POST /references';
    this._references.addCandidateReference(value).subscribe(
      (res: IReference) => {
        this._logger.info(this.constructorName, url, res);

        if (this.isReferenceContact) {
          this.patchReferenceContact(value);
        } else {
          this._toast.success('Reference added');

          this.resetModal();
          this.resetAll();
        }
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
        this.resetAll();
      }
    );
  }

  private patchReferenceContact(rc: IReferenceContact) {
    rc.is_outdated = true;

    const url = `POST /reference_contacts/${rc.id}`;
    this._referenceContacts.patch(new ReferenceContact(rc)).subscribe(
      (res: IReferenceContact) => {
        this._logger.info(this.constructorName, url, res);

        this._toast.success('Reference added');

        this.resetModal();
        this.resetAll();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
        this.resetAll();
      }
    );
  }

  private resetAll() {
    this.entry.enable();
    this.isButtonsDisabled = false;
  }

  private getReferenceRelationships() {
    const url = `GET /hiring_firms/${this.hiringFirm.id}/reference_relationships`;
    this._hiringFirms.getReferenceRelationships(this.hiringFirm.id).subscribe(
      (res: IReferenceRelationship[]) => {
        this._logger.info(this.constructorName, url, res);
        this.relationships = res.filter(
          (r: IReferenceRelationship) => r.is_active
        );
      },
      (err) => {
        this._logger.info(this.constructorName, url, err);
      }
    );
  }

  private getRecruiters() {
    this.recruiters = [];

    const filter: any = {
      page_size: 250,
      filter: {
        key: 'is_active',
        value: true
      }
    };

    const url = String('GET /recruiters');
    this._recruiters.get(filter).subscribe(
      (res: IRecruiter[]) => {
        this._logger.info(this.constructorName, url, res);

        this.recruiters = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }
}
