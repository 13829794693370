import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from '@shared/directives/modal.directive';
import { languages } from '@shared/helpers/languages.helper';
import { IAPIData } from '@shared/interfaces/api.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { ICountry } from '@shared/interfaces/country.interface';
import { IFeedback } from '@shared/interfaces/feedback.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { IPreScreening } from '@shared/interfaces/pre-screening.interface';
import { IQuestionSet } from '@shared/interfaces/question-set.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { IReferenceRequest } from '@shared/interfaces/reference-request.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { ITab } from '@shared/interfaces/tab.interface';
import { Candidate } from '@shared/models/candidate.model';
import { Feedback } from '@shared/models/feedback.model';
import { PreScreening } from '@shared/models/pre-screening.model';
import { ReferenceRequest } from '@shared/models/reference-request.model';
import { CandidatesService } from '@shared/services/candidates.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { FeedbacksService } from '@shared/services/feedbacks.service';
import { LoggerService } from '@shared/services/logger.service';
import { PreScreeningsService } from '@shared/services/pre-screenings.service';
import { QuestionSetsService } from '@shared/services/question-sets.service';
import { RecruitersService } from '@shared/services/recruiters.service';
import { ReferencesService } from '@shared/services/references.service';
import { ToastService } from '@shared/services/toast.service';
import { EmailValidityValidator } from '@shared/validators/emailValidity.validators';
import { NumbersOnlyValidator } from '@shared/validators/numbersOnly.validators';
import jwtDecode from 'jwt-decode';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { LocalStorage, SessionStorage } from 'ngx-webstorage';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-modals-quick-request',
  templateUrl: './modals.quick-request.component.html'
})
export class ModalsQuickRequestComponent
  extends ModalDirective<any>
  implements OnInit, OnDestroy
{
  @ViewChild(TabsetComponent, { static: true }) public tabset: TabsetComponent;

  @LocalStorage() public isSubAccount: boolean;
  @SessionStorage() public settings: ISetting;
  @SessionStorage() private hiringFirm: IHiringFirm;
  @SessionStorage() private countries: ICountry[];

  public tabs: ITab[] = [];
  public activeTab: ITab;

  public entry: FormGroup;
  public items: IQuestionSet[] = [];
  public questionSets: IQuestionSet[] = [];
  public candidate: ICandidate;
  public title = String('MODALS.QUICK_REQUEST.TITLE');
  public description: string;
  public submitKey = String('BUTTONS.SUBMIT');
  public minReferencesCount: number;

  public isButtonsDisabled = Boolean(false);
  public isCandidatePermissionRequired = Boolean(false);
  public isCandidateEmailRequired = Boolean(false);
  public isMultiLanguagesEnable = Boolean(false);
  public isLoading = Boolean(true);

  public recruiters: IRecruiter[] = [];
  public languages: any[] = [];
  public language = String('en');

  public params: any = {
    type: 'candidate'
  };

  private readonly constructorName: string = String(this.constructor.name);

  private watcher: Subscription;
  private payload: IPayload;

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _candidates: CandidatesService,
    private readonly _fb: FormBuilder,
    private readonly _references: ReferencesService,
    private readonly _feedbacks: FeedbacksService,
    private readonly _preScreenings: PreScreeningsService,
    private readonly _router: Router,
    private readonly _toast: ToastService,
    private readonly _logger: LoggerService,
    private readonly _recruiters: RecruitersService,
    private readonly _questionSets: QuestionSetsService,

    private readonly _emailValidityValidator: EmailValidityValidator
  ) {
    super();

    this.onMatOptionSelect = this.onMatOptionSelect.bind(this);
  }

  public get isPhoneRequired() {
    const control = this.entry.get('candidate').get('phone') as FormGroup;
    const validator =
      control?.validator && control?.validator({} as AbstractControl);

    return validator?.required;
  }

  public get service(): CandidatesService {
    return this._candidates;
  }

  public get isNotesHidden() {
    return this.entry && this.entry.get('candidate').get('isNotesHidden').value;
  }

  public get isSearchCandidate() {
    return (
      this.entry && this.entry.get('candidate').get('isSearchCandidate').value
    );
  }

  public get isEmailRequired() {
    const control = this.entry.get('candidate').get('email') as FormGroup;
    const validator =
      control?.validator && control?.validator({} as AbstractControl);
    return validator?.required;
  }

  ngOnInit(): void {
    this.openModal.subscribe(() => {
      const token = this._commonEnvironments.getToken();
      this.payload = jwtDecode(token);

      this.description = 'MODALS.QUICK_REQUEST.DESCRIPTION.REFERENCE';
      this.languages = languages.filter(
        (l: any) => this.settings.languages.indexOf(l.id) > -1
      );

      this.isCandidatePermissionRequired = Boolean(
        this.settings.require_candidate_permission
      );

      this.isCandidateEmailRequired = Boolean(
        this.settings.is_candidate_email_required
      );

      this.isMultiLanguagesEnable = Boolean(
        this.settings.is_plugins_multi_languages_enabled
      );

      this.tabs = [
        {
          id: 0,
          label: 'TABS.REFERENCE',
          icon: 'lnr-contacts',
          isActive: true
        }
      ];

      this.setTabs();

      this.activeTab = this.tabs[0];

      this.getQuestionSets();
      this.setValidators();

      this.watcher = this.entry
        .get('candidate')
        .valueChanges.pipe(debounceTime(500))
        .subscribe((res: ICandidate) => {
          const candidateFormGroup = this.entry.get('candidate') as FormGroup;

          const emailControl = candidateFormGroup['controls']['email'];
          const phoneControl = candidateFormGroup['controls']['phone'];
          const candidateIdControl = candidateFormGroup['controls']['id'];

          const email = res.email;

          if (this.isSearchCandidate) {
            candidateIdControl.setValidators([Validators.required]);
          } else {
            candidateIdControl.setValidators([]);
            candidateFormGroup.patchValue({ id: '' });

            if (!!email || this.isCandidateEmailRequired) {
              emailControl.setValidators([
                Validators.email,
                Validators.required
              ]);
              phoneControl.setValidators([NumbersOnlyValidator.NumbersOnly]);
            } else {
              emailControl.setValidators([Validators.email]);
              phoneControl.setValidators([
                NumbersOnlyValidator.NumbersOnly,
                Validators.required
              ]);

              emailControl.markAsUntouched();
            }

            // emailControl.setAsyncValidators([
            //   this._emailValidityValidator.validates
            // ]);

            candidateIdControl.updateValueAndValidity({
              onlySelf: true,
              emitEvent: false
            });

            emailControl.updateValueAndValidity({
              onlySelf: true,
              emitEvent: false
            });

            phoneControl.updateValueAndValidity({
              onlySelf: true,
              emitEvent: false
            });
          }
        });

      this.getRecruiters();

      this.isLoading = false;
    });

    this.createForm();
  }

  ngOnDestroy(): void {
    this.watcher?.unsubscribe();
  }

  public selectTab(id: number, index: number): void {
    this.tabs.map((t: ITab, i: number) => {
      if (index === i) {
        t.isActive = true;
      } else {
        t.isActive = false;
      }
    });

    this.activeTab = this.tabs[index];
    this.candidate = null;
    this.errors = [];

    this.entry.enable();
    this.entry.markAsUntouched();

    switch (id) {
      case 1:
        this.description = 'MODALS.QUICK_REQUEST.DESCRIPTION.PRE_SCREENING';
        break;

      case 2:
        this.description = 'MODALS.QUICK_REQUEST.DESCRIPTION.FEEDBACK';
        break;

      case 3:
        this.description = 'MODALS.QUICK_REQUEST.DESCRIPTION.VIDEO';
        break;

      case 0:
      default:
        this.description = 'MODALS.QUICK_REQUEST.DESCRIPTION.REFERENCE';
    }

    this.setValidators();
    this.filterQuestionSets();
  }

  public generateID() {
    const internalId = uuid().substring(0, 8);
    this.entry.get('candidate').patchValue({
      internal_id: internalId
    });
  }

  public onDismiss() {
    this.errors = [];

    this.entry.reset();
    this.resetAll();
  }

  public onSubmit({ valid, value }: { valid: boolean; value: any }): void {
    if (valid) {
      this.isButtonsDisabled = true;
      this.entry.disable();

      if (
        this.activeTab.id === 0 ||
        this.activeTab.id === 1 ||
        (this.activeTab.id === 2 && !this.isSearchCandidate) ||
        this.activeTab.id === 3
      ) {
        this.postCandidate(value);
      } else if (this.activeTab.id === 2 && this.isSearchCandidate) {
        this.postFeedbackRequest(value);
      }
    }
  }

  public onMatOptionSelect(c: ICandidate): void {
    this.candidate = c;
    this.entry.get('candidate').patchValue({
      id: c.id
    });
  }

  public setValidators() {
    const candidateFormGroup = this.entry.get('candidate') as FormGroup;
    const questionSetFormGroup = this.entry.get('request') as FormGroup;

    const id = candidateFormGroup['controls']['id'];
    const internalId = candidateFormGroup['controls']['internal_id'];
    const firstName = candidateFormGroup['controls']['first_name'];
    const lastName = candidateFormGroup['controls']['last_name'];
    const email = candidateFormGroup['controls']['email'];
    const phone = candidateFormGroup['controls']['phone'];
    const grantedPermission =
      candidateFormGroup['controls']['granted_permission'];

    const questionSet = questionSetFormGroup['controls']['question_set'];

    switch (this.activeTab.id) {
      // Pre Screening
      case 1:
        id.setValidators([]);
        internalId.setValidators([
          Validators.required,
          Validators.maxLength(63)
        ]);
        firstName.setValidators([Validators.required]);
        lastName.setValidators([Validators.required]);

        if (this.isCandidateEmailRequired) {
          email.setValidators([Validators.email, Validators.required]);
          phone.setValidators([NumbersOnlyValidator.NumbersOnly]);
        } else {
          email.setValidators([Validators.email]);
          phone.setValidators([
            NumbersOnlyValidator.NumbersOnly,
            Validators.required
          ]);
        }

        // email.setAsyncValidators([this._emailValidityValidator.validates]);

        grantedPermission.setValidators([]);
        break;

      // Feedback
      case 2:
        if (this.isSearchCandidate) {
          id.setValidators([Validators.required]);
          internalId.setValidators([]);
          firstName.setValidators([]);
          lastName.setValidators([]);
          email.setValidators([]);
          phone.setValidators([]);
          grantedPermission.setValidators([]);
        } else {
          id.setValidators([]);
          internalId.setValidators([
            Validators.required,
            Validators.maxLength(63)
          ]);
          firstName.setValidators([Validators.required]);
          lastName.setValidators([Validators.required]);

          if (this.isCandidateEmailRequired) {
            email.setValidators([Validators.email, Validators.required]);
            phone.setValidators([NumbersOnlyValidator.NumbersOnly]);
          } else {
            email.setValidators([Validators.email]);
            phone.setValidators([
              NumbersOnlyValidator.NumbersOnly,
              Validators.required
            ]);
          }

          grantedPermission.setValidators([]);
        }

        break;

      // Reference
      case 0:
      default:
        id.setValidators([]);
        internalId.setValidators([
          Validators.required,
          Validators.maxLength(63)
        ]);

        firstName.setValidators([Validators.required]);
        lastName.setValidators([Validators.required]);

        if (this.isCandidatePermissionRequired) {
          questionSet.setValidators([]);
        } else {
          questionSet.setValidators([Validators.required]);
        }

        if (this.isCandidateEmailRequired) {
          email.setValidators([Validators.email, Validators.required]);
          phone.setValidators([NumbersOnlyValidator.NumbersOnly]);
        } else {
          email.setValidators([Validators.email]);
          phone.setValidators([
            NumbersOnlyValidator.NumbersOnly,
            Validators.required
          ]);
        }

        grantedPermission.setValidators([Validators.required]);
        break;
    }

    // email.setAsyncValidators([this._emailValidityValidator.validates]);

    this.entry.get('request').patchValue({
      question_set: ''
    });

    id.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });

    internalId.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });

    firstName.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });

    lastName.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });

    email.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });

    phone.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });

    grantedPermission.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });

    questionSet.markAsUntouched();
    questionSet.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });
  }

  public trackByFn(i: ITab): number {
    return i.id;
  }

  public onChangeRecruiter() {
    const value = this.entry.get('candidate').value.added_by_id;

    this.entry.patchValue({
      candidate: {
        added_by_id: value
      },
      request: {
        request_by: value
      }
    });
  }

  protected createForm() {
    this.entry = this._fb.group({
      candidate: this._fb.group({
        id: [''],
        added_by_id: ['', [Validators.required]],
        internal_id: ['', [Validators.required, Validators.maxLength(63)]],
        job_title: [''],
        first_name: ['', [Validators.required]],
        last_name: ['', [Validators.required]],
        preferred_name: [''],
        email: [''],
        notes: [''],
        phone: ['', [NumbersOnlyValidator.NumbersOnly]],
        countryInitial: [''],
        country_code: [''],
        granted_permission: ['', [Validators.required]],
        isNotesHidden: [false],
        isSearchCandidate: [false]
      }),
      request: this._fb.group({
        request_by: ['', [Validators.required]],
        candidate: ['', []],
        question_set: ['', []],
        language: ['en', []],
        min_manager_references: [1],
        is_manager_only: [false],
        notes: ['']
      })
    });
  }

  private postFeedbackRequest(value: any) {
    const candidate = this.candidate || value.candidate;
    const feedback = new Feedback({
      candidate,
      question_set: this.items.find(
        (qs: IQuestionSet) => qs.id === value.request.question_set
      ),
      scheduled_date: '',
      notes: value.request.notes
    });

    const url = 'POST /feedbacks';
    this._feedbacks.post(feedback.apiData).subscribe(
      (res: IFeedback) => {
        this._logger.info(this.constructorName, url, res);

        this._router.navigate(['/candidates', candidate.id, 'feedbacks']);

        this.isButtonsDisabled = false;
        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
        this.isButtonsDisabled = false;
      }
    );
  }

  private postPreScreeningRequest(c: ICandidate, request: IReferenceRequest) {
    const ps = new PreScreening({
      candidate: c,
      question_set: this.items.find(
        (qs: IQuestionSet) => qs.id === String(request.question_set)
      ),
      notes: request.notes
    });

    const url = 'POST /pre_screenings';
    this._preScreenings.post(ps.apiData).subscribe(
      (res: IPreScreening) => {
        this._logger.info(this.constructorName, url, res);

        this._router.navigate(['/candidates', c.id, 'pre-screenings']);

        this.isButtonsDisabled = false;
        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
        this.isButtonsDisabled = false;
      }
    );
  }

  private postCandidate(value: any) {
    const candidate = value.candidate;
    const request = value.request;

    const url = 'POST /candidates';
    this._candidates.post(new Candidate(candidate).apiData).subscribe(
      (res: ICandidate) => {
        this._logger.info(this.constructorName, url, res);

        // Sending reference request
        if (this.activeTab.id === 0) {
          const isSendingReferenceRequest =
            !this.isCandidatePermissionRequired &&
            ((!this.isCandidateEmailRequired &&
              (candidate.phone || candidate.email)) ||
              this.isCandidateEmailRequired);

          if (!isSendingReferenceRequest) {
            this._toast.success('Candidate added');

            this.resetModal();
            this.resetAll();

            // NOT IMPLEMENTED
            // this._router.navigate(['/candidates', res.id, 'references']);
            this._router.navigate(['/candidates', res.id]);
          } else {
            this.postReferenceRequest(res, request);
          }
        } else if (this.activeTab.id === 1) {
          this.postPreScreeningRequest(res, request);
        } else if (this.activeTab.id === 3) {
          this.sendInterviewInvitation(res);
        } else if (this.activeTab.id === 2 && !this.isSearchCandidate) {
          this.postFeedbackRequest({ candidate: res, request });
        }
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
        this.resetAll();
        this.isLoading = false;
      }
    );
  }

  private postReferenceRequest(
    c: ICandidate,
    request: Partial<IReferenceRequest>
  ) {
    const rr: IReferenceRequest = new ReferenceRequest({
      candidate: c,
      question_set: this.items.find(
        (qs: IQuestionSet) => qs.id === String(request.question_set)
      ),
      request_by: this.recruiters.find(
        (r: IRecruiter) => r.id === String(request.request_by)
      ),
      notes: request.notes,
      min_manager_references: request.min_manager_references,
      is_manager_only: request.is_manager_only,
      language: request.language
    });

    const url = 'POST /reference_requests';
    this._references.postRequest(rr).subscribe(
      (res: IAPIData) => {
        this._logger.info(this.constructorName, url, res);

        this._toast.success('Reference requested');
        this.resetModal();
        this.resetAll();

        // NOT IMPLEMENTED
        // this._router.navigate(['/candidates', c.id, 'references']);
        this._router.navigate(['/candidates', c.id]);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        // TO DO: WAITING FOR PROPER HANDLING FROM API
        this.errors = err;
        this.resetAll();
        this.isLoading = false;
      }
    );
  }

  private sendInterviewInvitation(c: ICandidate) {
    const id = c.id;

    const url = `POST /candidates/${id}/invite`;
    this._candidates.postInvite(id).subscribe(
      (res: ICandidate) => {
        this._logger.info(this.constructorName, url, res);

        this._toast.success('Invitation sent');
        // NOT IMPLEMENTED
        // this._router.navigate(['/candidates', c.id, 'references']);
        this._router.navigate(['/candidates', c.id]);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this._toast.error(err[0].detail);
      }
    );
  }

  private getQuestionSets() {
    this.items = [];

    const filters = {
      page_size: 50
    };

    const url = 'GET /question_sets';
    this._questionSets.get(filters).subscribe(
      (res: IQuestionSet[]) => {
        this._logger.info(this.constructorName, url, res);

        this.questionSets = res;
        this.filterQuestionSets();

        this.setDataForm();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  private setDataForm() {
    this.isCandidatePermissionRequired =
      this.settings.require_candidate_permission;
    this.isCandidateEmailRequired = this.settings.is_candidate_email_required;
    this.isMultiLanguagesEnable =
      this.settings.is_plugins_multi_languages_enabled;

    this.minReferencesCount = Number(this.settings.min_reference_count) || 0;
    const country = this.countries.find(
      (c: ICountry) => c.initial === this.hiringFirm.country
    );

    const defaultQs = this.items.find((qs: IQuestionSet) => qs.is_default);

    if (!!defaultQs) {
      this.entry.get('request').patchValue({ question_set: defaultQs.id });
    }

    this.entry.get('candidate').patchValue({
      countryInitial: country?.initial,
      country_code: country?.phoneCode,
      granted_permission: !this.settings.require_candidate_permission
    });

    this.entry
      .get('request')
      .patchValue({ min_manager_references: this.minReferencesCount });
  }

  private resetAll() {
    this.entry.enable();
    this.isButtonsDisabled = false;
    this.isLoading = true;
  }

  private filterQuestionSets() {
    this.items = this.questionSets.filter((qs: IQuestionSet) => {
      switch (this.activeTab.id) {
        // Feedback
        case 2:
          return (
            qs.identifiable_type === 'Feedback'
          );

        // Pre Screening
        case 1:
          return qs.identifiable_type === 'PreScreening';

        // Reference
        case 0:
        default:
          return qs.identifiable_type === 'Reference';
      }
    });
  }

  private setTabs() {
    // Pre screening
    const isPreScreeningEnabled = this.tabs.find(
      (l: any) => l.label === 'TABS.PRE_SCREENING'
    );

    if (
      this.settings.is_plugins_pre_screening_enabled &&
      !isPreScreeningEnabled
    ) {
      this.tabs.push({
        id: 1,
        label: 'TABS.PRE_SCREENING',
        icon: 'lnr-folder-user',
        isActive: false
      });
    }

    // Feedback
    const isCandidateFeedbackEnabled = this.tabs.find(
      (l: any) => l.label === 'TABS.FEEDBACK'
    );

    if (
      this.settings.is_plugins_candidate_feedback_enabled &&
      !isCandidateFeedbackEnabled
    ) {
      this.tabs.push({
        id: 2,
        label: 'TABS.FEEDBACK',
        icon: 'lnr-thumbs-up',
        isActive: false
      });
    }

    // Resume
    const isVideoEnabled = this.tabs.find((l: any) => l.label === 'TABS.VIDEO');
    if (this.settings.is_plugins_video_enabled && !isVideoEnabled) {
      this.tabs.push({
        id: 3,
        label: 'TABS.VIDEO',
        icon: 'lnr-camera-play',
        isActive: false
      });
    }
  }

  private getRecruiters() {
    this.recruiters = [];

    const filter: any = {
      page_size: 250,
      filter: {
        key: 'is_active',
        value: true
      }
    };

    const url = String('GET /recruiters');
    this._recruiters.get(filter).subscribe(
      (res: IRecruiter[]) => {
        this._logger.info(this.constructorName, url, res);

        this.recruiters = res;
        const addedById = this.payload.recruiter_id;

        this.entry.get('candidate').patchValue({
          added_by_id: addedById
        });

        this.entry.get('request').patchValue({
          request_by: addedById
        });
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }
}
