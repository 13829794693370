export enum ReferenceRelationshipEnum {
  PEER = 'P',
  DIRECT_MANAGER = 'D',
  NON_DIRECT_MANAGER = 'N',
  HR = 'H',
  MENTOR = 'M',
  OTHER = 'O',
  UNSPECIFIED = 'U',
  EDUCATIONAL = 'E',
  COMMUNITY_LEADER = 'C'
}

export enum ReferenceContactEnum {
  HIRING = 'H',
  CANDIDATE = 'C',
  IP = 'I',
  NA = 'N'
}
